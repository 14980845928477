import React from 'react'

import dynamic from 'next/dynamic'

import classnames from 'classnames'
import { Button, CloudinaryImage } from 'ethos-design-system'

import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../Trustworthy.module.scss'
import { SingleCTAInterface } from '../interface'

const IconV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Icon),
  { ssr: false }
)

const Trustworthy = ({
  moduleData,
  ctaClick,
  policiesApproved,
}: {
  moduleData: SingleCTAInterface
  ctaClick: (flow?: string, label?: string) => void
  policiesApproved?: number
}) => {
  const { arrowIcon, ctaLabel, fiveMinutesHeading, heading, listType } =
    moduleData

  const { isTreatment } = useTrustageLandingPage()
  const { headline } = useHeadlineRapidTest()

  const headingToShow = isTreatment
    ? 'Instant <br/> Life insurance'
    : headline || heading

  const policiesApprovedFormatted =
    policiesApproved?.toLocaleString('en-US') || '1,018'

  const defaultCardsInfo = [
    '**$2M** in coverage starts at **$2/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  const treatmentCardsInfo = [
    '**$500k** in coverage starts at **$1/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  let cardsInfo
  if (isTreatment) cardsInfo = treatmentCardsInfo
  else cardsInfo = defaultCardsInfo

  const trustImages = features.SINGLE_CTA.trustImages

  const bgImage = isTreatment
    ? 'https://res.cloudinary.com/getethos/image/upload/v1732263928/AdobeStock_410597901_web_cutout_1_qzmrz2.webp'
    : 'https://res.cloudinary.com/getethos/image/upload/v1736768846/a4a80674302dbb345546ec7ae210e8eb_trg43e_kc13kv.webp'

  return (
    <div className={classnames(styles.trustworthy)}>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <h1
            className={classnames(styles.header, {
              [styles.fiveMinutesHeading]: fiveMinutesHeading,
            })}
          >
            <Markdown input={headingToShow || ''}></Markdown>
          </h1>
          {listType === 'checkmarks' ? (
            <div className="mb-6">
              {cardsInfo.map((card) => (
                <div
                  key={card}
                  className="mb-2 flex items-center gap-2 last:mb-0"
                >
                  <IconV2 name={'check'} sx={{ color: '#FFFFFF' }} />
                  <p className="body-s-reg text-white">{card}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className={classnames(styles.bentoContainer)}>
              {cardsInfo.map((card) => (
                <div key={card} className={styles.box}>
                  <div className={classnames(styles.boxContent)}>
                    <Markdown input={card} />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className={styles.cta}>
            <Button.Unstyled
              fullWidth
              arrowIcon={!!arrowIcon}
              onClick={() => ctaClick()}
            >
              {ctaLabel}
            </Button.Unstyled>
          </div>
          <div className={styles.trustImages}>
            {trustImages.map((trustImage, index) => (
              <div
                key={`${trustImage.title}_${index}`}
                className={styles.trustImageWrapper}
              >
                <div
                  key={`trustImage-${index}`}
                  className={classnames(styles.trustImageContainer)}
                >
                  <CloudinaryImage
                    publicId={trustImage.imageUrl}
                    alt={trustImage.alt}
                    className={styles.trustImage}
                    crop={CloudinaryImage.CROP_METHODS.FIT}
                    height={[55, 55, 55, 55]}
                    width={[90, 90, 90, 90]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={classnames(styles.bgImage, {
            [styles.tsBgImage]: isTreatment,
          })}
        >
          <PriorityImage
            publicId={bgImage}
            fetchpriority="high"
            alt="Father mother and child hugging"
            crop={CloudinaryImage.CROP_METHODS.FIT}
            height={[600, 768, 1440, 1920]}
            width={[300, 384, 729, 972]}
          />
        </div>
      </div>
    </div>
  )
}

const features = {
  SINGLE_CTA: {
    trustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1736775348/forbes_with_text_yzdnw0.svg',
        alt: 'Forbes',
        title: 'No. 1 instant life insurance',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1712744192/business_insider_with_text_ooheuy.svg',
        alt: 'Business Insider',
        title: `Best no medical exam <br/> term life policy`,
      },
    ],
    willsTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007064_yrt25p.svg',
        alt: '2 million families trust ethos',
        title: '2 million families trust ethos',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007066_ua57ii.svg',
        alt: 'Best value for the money',
        title: 'Best value for the money',
      },
    ],
  },
}

export default Trustworthy
