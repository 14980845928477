import { HEADLINE_RAPID_TEST } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const variationContentMap = {
  [HEADLINE_RAPID_TEST.VARIATIONS.CONTROL]: null,
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_1]: 'Get a great rate today',
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_2]: 'A faster way to life insurance',
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_3]: 'Life insurance made simple',
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_4]:
    'Great rates from trusted insurers',
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_5]: "Protect your family's future",
  [HEADLINE_RAPID_TEST.VARIATIONS.VARIANT_6]: 'Life Insurance in 10 minutes',
}

export const useHeadlineRapidTest = () => {
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: HEADLINE_RAPID_TEST.EXPERIMENT_KEY,
    fallbackVariation: HEADLINE_RAPID_TEST.VARIATIONS.CONTROL,
  })

  const headline = variationContentMap[variation]

  return {
    isEnabled,
    isLoading,
    headline,
  }
}
