import React, { memo } from 'react'

import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { mapQueryToUserData } from 'lib/@getethos/utils/mapQueryToUserData'
import { getQueryString } from 'lib/@getethos/utils/utils'
import isEmpty from 'lodash.isempty'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import { useWillsFunnelFlow } from '@/hooks/features/useWillsFunnelFlow'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import SingleCTADirect from '../EstimateWidget/FloatingVariation/Layouts/SingleCTADirect/SingleCTADirect'
import { Loader } from '../global/Loader/Loader'
import styles from './SingleCTA.module.scss'
import { SingleCTAInterface } from './interface'
import { BackgroundImage, CurvedOverlay, ForegroundImage } from './layouts'
import FinalExpense from './layouts/FinalExpense'
import Trustworthy from './layouts/Trustworthy'
import Wills from './layouts/Wills'

const SingleCTA = ({
  moduleData,
  settings,
}: {
  moduleData: SingleCTAInterface
  settings: any
}) => {
  const {
    isLoading: isLoadingWillsFunnel,
    isTreatment,
    flow,
    isLeadForm,
  } = useWillsFunnelFlow()

  const { isLoading: isLoadingHeadlineRapidTest } = useHeadlineRapidTest()

  const isLoading = isLoadingWillsFunnel || isLoadingHeadlineRapidTest

  const { policiesApproved } = useGlobalSettings(
    'policies-approved-count.md',
    settings
  )

  const featureFlagConfig = {
    useRatesCopy: moduleData.ratesCopy || false,
  }

  const { handleNavigateToApp } = useNavigateToApp()
  const queryParameters = getQueryString()
  const queryParamData = mapQueryToUserData(queryParameters || {})
  const userData = isEmpty(queryParamData) ? {} : queryParamData

  // admin override for query parameters
  if (moduleData.queryParams) {
    const paramsArray = moduleData.queryParams.split('&')
    for (const param of paramsArray) {
      const paramArray = param.split('=')
      const key = paramArray[0]
      const value = paramArray[1]
      if (key && value) {
        userData[key] = value
      }
    }
  }

  const ctaData = {
    module: 'Single CTA',
    ctaLabel: moduleData.ctaLabel,
    userData,
    newTab: moduleData.newTab,
    featureFlagConfig,
    clickthroughUrl:
      isTreatment || !moduleData.clickthroughUrl
        ? ''
        : moduleData.clickthroughUrl,
    flow,
  }

  if (isLeadForm) {
    moduleData.disableNavigateToApp = true
  }

  const handleCtaClick = (flow?: string, label?: string): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: ctaData.module,
        clickthroughUrl: ctaData.clickthroughUrl,
        ctaLabel: label || moduleData.ctaLabel,
        userData: ctaData.userData,
        flow: flow || ctaData.flow,
      },
      callback: null,
    })

    if (!moduleData.disableNavigateToApp) {
      handleNavigateToApp(
        ctaData.userData, //prefill user data
        typeof ctaData.newTab === 'boolean' && ctaData.newTab, // open in new tab
        false, // needs route
        ctaData.featureFlagConfig && ctaData.featureFlagConfig.useRatesCopy, // rates copy in main app
        ctaData.clickthroughUrl, // override CTA path to app with editor given URL,
        flow || ctaData.flow
      )
    }
  }

  const modules = {
    background: (
      <BackgroundImage
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
      />
    ),
    curvedOverlay: (
      <CurvedOverlay
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
      />
    ),
    direct: <SingleCTADirect ctaClick={() => handleCtaClick()} />,
    foreground: (
      <ForegroundImage
        moduleData={moduleData}
        ctaClick={() => handleCtaClick()}
        policiesApproved={policiesApproved}
      />
    ),
    finalExpense: (
      <FinalExpense moduleData={moduleData} ctaClick={() => handleCtaClick()} />
    ),
    trustworthy: (
      <Trustworthy
        moduleData={moduleData}
        ctaClick={handleCtaClick}
        policiesApproved={policiesApproved}
      />
    ),
    wills: <Wills moduleData={moduleData} ctaClick={() => handleCtaClick()} />,
  }

  const ModuleToRender = modules[moduleData.imageLayout as keyof typeof modules]

  return (
    <div className={styles.wrapper}>
      <Loader isLoading={isLoading} />
      {ModuleToRender}
    </div>
  )
}

export default memo(SingleCTA)
